<template>
  <div>
    <!-- 地址 -->
    <div class="flex width-100 jcsb p-t-10" @click="goedit">
      <div class="width-100" style="margin-left: 1.1rem;">
        <div class="flex">
          <span style="font-size: 1.1rem ; font-weight: 600;">{{ name }}</span>
          <span v-if="tel" style="margin-left: 0.5rem;font-size: 1.1rem ; font-weight: 600;"> {{ getPhone(tel) }}</span>
        </div>
        <div class="p-tb-10" style="font-size: 1rem ; color: #464646;">
          {{ address }}
        </div>
      </div>
      <div class="flex">
        <img src="../../assets/images/dizhi.svg" alt="" />
        <van-icon style="margin-left: 0.5rem;margin-right: 1.1rem;" name="arrow" color="#999" />
      </div>
    </div>
    <div>
      <img src="../../assets/images/caitiao.svg" alt="" class="cai" />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    name: {
      type: String,
    },
    tel: {
      type: String,
    },
    address: null,
  },
  data() {
    return {};
  },
  components: {},
  methods: {
    getPhone(phone) {
      return this.$utils.formPhone(phone);
    },
    // 修改收获地址
    goedit() {
      this.$emit("EditAddress");
    },
  },
  mounted() { },
  computed: {},
  watch: {
    address(value) {
      console.log('     avalue', value);
    }


  },
};
</script>

<style lang='scss' scoped>
.orange {
  color: rgb(238, 146, 8);
}

.cai {
  width: 100%;
}
</style>